const ASYNC_OPERATION_STATUSES = {
    RUNNING: 'RUNNING',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR',
};
const PAGE_ID_COMPANY_RESEARCH = 'company-research';
const PAGE_ID_AICPS = 'aicps';
const PAGE_ID_AICP = 'aicp';
const PAGE_ID_AICP_ANALYSIS = 'aicp-analysis';
const PAGE_ID_AICP_ANALYSIS_EXPLORATION = 'aicp-analysis-exploration';
const PAGE_ID_AICP_ANALYSIS_EXEGRAPHIC_LENSES =
    'aicp-analysis-exegraphic-lenses';
const PAGE_ID_AICP_ANALYSIS_FIRMOGRAPHICS = 'aicp-analysis-firmographics';
const PAGE_ID_AICP_ANALYSIS_LOOKALIKE_COMPANIES =
    'aicp-analysis-lookalike-companies';
const PAGE_ID_AICP_SETTINGS_USER_ASSIGNMENT = 'aicp-settings-user-assignment';
const PAGE_ID_AICP_SETTINGS_TUNING = 'aicp-settings-tuning';
const PAGE_ID_AICP_NOTES = 'aicp-notes';
const PAGE_ID_COMPANY_LOOKUP = 'company-lookup';
const PAGE_ID_EXPORT_HISTORY = 'export-history';
const PAGE_ID_EXPORT_HISTORY_FAILURES = 'export-history-failures';
const PAGE_ID_SIGNAL_CHAT = 'signal-chat';
const PAGE_ID_EXPLORE_SIGNALS = 'signal-explore';
const PAGE_ID_SIGNALS = 'signal-saved';
const PAGE_ID_SIGNAL_NEW = 'signal-new';
const PAGE_ID_P_VALUES_MODEL = 'p-values-model';

export {
    ASYNC_OPERATION_STATUSES,
    PAGE_ID_COMPANY_RESEARCH,
    PAGE_ID_AICPS,
    PAGE_ID_AICP,
    PAGE_ID_AICP_ANALYSIS,
    PAGE_ID_AICP_ANALYSIS_EXPLORATION,
    PAGE_ID_AICP_ANALYSIS_EXEGRAPHIC_LENSES,
    PAGE_ID_AICP_ANALYSIS_FIRMOGRAPHICS,
    PAGE_ID_AICP_ANALYSIS_LOOKALIKE_COMPANIES,
    PAGE_ID_AICP_NOTES,
    PAGE_ID_AICP_SETTINGS_USER_ASSIGNMENT,
    PAGE_ID_AICP_SETTINGS_TUNING,
    PAGE_ID_COMPANY_LOOKUP,
    PAGE_ID_EXPORT_HISTORY,
    PAGE_ID_EXPORT_HISTORY_FAILURES,
    PAGE_ID_SIGNAL_CHAT,
    PAGE_ID_EXPLORE_SIGNALS,
    PAGE_ID_SIGNALS,
    PAGE_ID_SIGNAL_NEW,
    PAGE_ID_P_VALUES_MODEL,
};
