import {Theme} from '@mui/material/styles';
import styled from 'styled-components';
import {Box, Typography} from 'components/mui';
import {NavMenuItem} from 'components/ui/menu/MenuItem';
import ArrowTooltip from 'components/ui/tooltips/ArrowTooltip';
import {useUserContext} from 'components/contexts/user';
import _ from 'lodash';
import {Roles} from 'src/types/graphql-types';

type NavMenuLabelCustomProps = {
    theme: Theme;
    $isActive: boolean;
};

const NavMenuLabel = styled(Typography)<NavMenuLabelCustomProps>`
    font-weight: ${({theme}) => theme.typography.fontWeightBold};
    color: ${(props) =>
        props.$isActive
            ? `${props.theme.palette.background.default}`
            : `${props.theme.palette.secondary.light}`};
    text-decoration: ${(props) => (props.$isActive ? 'underline' : 'none')};
    &:hover: {
        text-decoration: 'underline';
    }
`;

type NavigationProps = {
    activeNavItem: string;
    navItems: {
        id: string;
        href: string;
        label?: string;
        isSuperUserOnly?: boolean;
        featureFlag?: string;
        tooltipPlacement?: string;
        requiredRoles?: Partial<Roles>;
        onClick?: () => void;
    }[];
    navigationExpanded?: boolean;
};

const Navigation = ({
    activeNavItem,
    navItems,
    navigationExpanded,
}: NavigationProps) => {
    const user = useUserContext();
    if (navigationExpanded) {
        return (
            <>
                {navItems
                    .filter(
                        (navItem) =>
                            !navItem?.isSuperUserOnly ||
                            (navItem?.isSuperUserOnly && user?.isSuperuser) ||
                            _.isMatch(user.roles, navItem.requiredRoles)
                    )
                    .map((navItem) => {
                        return (
                            <NavMenuItem
                                key={navItem.id}
                                menuItemId={navItem.id}
                                href={navItem.href}
                                activeNavItem={activeNavItem}
                                onClick={navItem.onClick}
                                open={navigationExpanded}
                            >
                                <NavMenuLabel
                                    $isActive={activeNavItem === navItem.id}
                                    variant="subtitle2"
                                    data-testid={`${navItem.id}-menu-item-label`}
                                >
                                    {navItem.label}
                                </NavMenuLabel>
                            </NavMenuItem>
                        );
                    })}
            </>
        );
    }
    return (
        <>
            <Box>
                {navItems
                    .filter(
                        (navItem) =>
                            !navItem?.isSuperUserOnly ||
                            (navItem?.isSuperUserOnly && user?.isSuperuser) ||
                            _.isMatch(user.roles, navItem.requiredRoles)
                    )
                    .map((navItem) => {
                        return (
                            <ArrowTooltip
                                key={navItem.id}
                                content={navItem.label}
                                placement={navItem.tooltipPlacement}
                            >
                                <div>
                                    <NavMenuItem
                                        menuItemId={navItem.id}
                                        href={navItem.href}
                                        activeNavItem={activeNavItem}
                                        onClick={navItem.onClick}
                                    />
                                </div>
                            </ArrowTooltip>
                        );
                    })}
            </Box>
        </>
    );
};

export {Navigation};
