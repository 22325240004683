import React from 'react';
import styled from 'styled-components';
import {
    PAGE_ID_COMPANY_RESEARCH,
    PAGE_ID_AICPS,
    PAGE_ID_COMPANY_LOOKUP,
    PAGE_ID_EXPLORE_SIGNALS,
    PAGE_ID_P_VALUES_MODEL,
} from 'components/util/constants';
import Image from 'next/image';

import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';

const icons = {
    default: {
        [PAGE_ID_COMPANY_RESEARCH]: (
            <Image
                src="/components/menu/icon-prospects-disabled.svg"
                width="18"
                height="18"
                alt="Company Research"
            />
        ),
        [PAGE_ID_AICPS]: (
            <Image
                src="/components/menu/icon-aicp-disabled.svg"
                width="18"
                height="18"
                alt="aiCPs"
            />
        ),

        [PAGE_ID_COMPANY_LOOKUP]: (
            <Image
                src="/components/menu/icon-company-lookup-disabled.svg"
                width="18"
                height="18"
                alt="Company Lookup"
            />
        ),
        [PAGE_ID_EXPLORE_SIGNALS]: (
            <Image
                src="/components/menu/icon-lens-builder-disabled.svg"
                width="18"
                height="18"
                alt="Signals"
            />
        ),
        [PAGE_ID_P_VALUES_MODEL]: (
            <PsychologyAltOutlinedIcon sx={{fill: 'gray'}} />
        ),
    },
    active: {
        [PAGE_ID_COMPANY_RESEARCH]: (
            <Image
                src="/components/menu/icon-prospects-active.svg"
                width="18"
                height="18"
                alt="Company Research"
            />
        ),
        [PAGE_ID_AICPS]: (
            <Image
                src="/components/menu/icon-aicp-active.svg"
                width="18"
                height="18"
                alt="aiCPs"
            />
        ),
        [PAGE_ID_COMPANY_LOOKUP]: (
            <Image
                src="/components/menu/icon-company-lookup-active.svg"
                width="18"
                height="18"
                alt="Company Lookup"
            />
        ),
        [PAGE_ID_EXPLORE_SIGNALS]: (
            <Image
                src="/components/menu/icon-lens-builder-active.svg"
                width="18"
                height="18"
                alt="Signals"
            />
        ),
        [PAGE_ID_P_VALUES_MODEL]: (
            <PsychologyAltOutlinedIcon sx={{fill: 'gray'}} />
        ),
    },
};

const getIcons = (isActive: boolean) => {
    return isActive ? icons.active : icons.default;
};

const IconContainer = styled.div`
    width: 33px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`;

type MenuIconProps = {
    menuItemId: string;
    isActive: boolean;
};

const MenuIcon = ({menuItemId, isActive = false}: MenuIconProps) => {
    if (!getIcons(isActive)[menuItemId]) {
        throw Error(
            `${menuItemId} is not a valid icon id.
            Make sure it is defined in the icons object in \`MenuIcon.jsx\``
        );
    }

    return <IconContainer>{getIcons(isActive)[menuItemId]}</IconContainer>;
};

export {MenuIcon};
