import {
    PAGE_ID_COMPANY_RESEARCH,
    PAGE_ID_AICPS,
    PAGE_ID_COMPANY_LOOKUP,
    PAGE_ID_EXPLORE_SIGNALS,
    PAGE_ID_P_VALUES_MODEL,
} from 'components/util/constants';
import {
    AICPS_URL,
    COMPANY_RESEARCH_URL,
    COMPANY_LOOKUP_URL,
    EXPLORE_SIGNALS_URL,
    P_VALUES_MODEL_URL,
} from 'components/util/routeConstants';

const navItems = [
    {
        id: PAGE_ID_EXPLORE_SIGNALS,
        href: EXPLORE_SIGNALS_URL,
        label: 'Signals',
    },
    {
        id: PAGE_ID_AICPS,
        href: AICPS_URL,
        label: 'aiCPs',
    },
    {
        id: PAGE_ID_COMPANY_LOOKUP,
        href: COMPANY_LOOKUP_URL,
        label: 'Company Lookup',
    },
    {
        id: PAGE_ID_COMPANY_RESEARCH,
        href: COMPANY_RESEARCH_URL,
        label: 'Company Research',
    },
    {
        id: PAGE_ID_P_VALUES_MODEL,
        href: P_VALUES_MODEL_URL,
        label: 'Signal Correlation',
        isSuperUserOnly: true,
        requiredRoles: {
            analyst: ['signal-correlation'],
        },
    },
];

const mainDesktopNavItems = navItems.map((navItem) => ({
    ...navItem,
    tooltipPlacement: 'right',
}));

const mainMobileNavItems = navItems.map((navItem) => ({
    ...navItem,
    tooltipPlacement: 'bottom',
}));

export {mainDesktopNavItems, mainMobileNavItems};
